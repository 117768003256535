import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classNames from './styles.module.scss'


Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h3-finder', 'h3-title', 'h4-card', 'h4', 'h5', 'h6', 'block-title', 'content', 'collapse-content', 'subhead', 'small', 'gray', 'small-gray', 'label', 'content-title', 'list-item', 'filter', 'tooltip', 'orm-checkbox', 'header-label', 'form-radio', 'big-label', 'reevoo-label', 'footer-title', 'footer-link', 'small-gray-cart', 'error', 'aboutHero', 'quote-card-title', 'form-subsection', 'form-checkbox', 'p']),
  children: PropTypes.node,
  align: PropTypes.string,
  tag: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  transform: PropTypes.string,
  fontStyle: PropTypes.string,
}

Typography.defaultProps = {
  tag: undefined,
  variant: 'content',
  align: 'left',
  children: undefined,
  color: 'black',
  className: undefined,
  transform: '',
  fontStyle: undefined,
}

export default function Typography({ variant, children, tag, color, className, align, transform, fontStyle, ...rest }) {
  let element
  switch (variant) {
  case 'h1':
  case 'h2':
  case 'h3':
  case 'h4':
  case 'h5':
  case 'h6':
    element = variant
    break
  case 'h3-finder':
    element = 'h3'
    break
  case 'h4-card':
    element = 'h4'
    break
  case 'subhead':
  case 'list-item':
  case 'tooltip':
  case 'reevoo-label':
    element = 'span'
    break
  case 'body':
  case 'content':
  case 'error':
  default:
    element = 'p'
    break
  }
  element = tag || element
  return React.createElement(
    element,
    {
      className: classnames(classNames[variant], classNames[color], align, transform, fontStyle, className),
      ...rest,
    },
    children
  )
}
// <Typography variant="body" tag="span">some text</Typography>
